import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Styles from "./audioPlayerImage.module.css"

const audioPlayerImage = props => {
  const imageAudio = props.image

  return (
    <StaticQuery
      query={graphql`
        query {
          imageAudio: allFile(filter: { relativeDirectory: { eq: "audio" } }) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className={Styles.images} key={props.title}>
          <Img
            fadeIn={true}
            durationFadeIn={2000}
            alt=""
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              borderRadius: "4px",
            }}
            fixed={
              data.imageAudio.edges.filter(element =>
                element.node.childImageSharp.fixed.src.includes(imageAudio)
              )[0].node.childImageSharp.fixed
            }
          />
        </div>
      )}
    />
  )
}

export default audioPlayerImage
