import React from "react"
import { graphql } from "gatsby"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styles from "../components/transition.module.css"

const transitionList = [
  {
    transition: "transition1",
    image: "sablier.jpg",
    title: "Méthode d'enseignement",
  },
  { transition: "transition2", image: "lune.jpg", title: "Ma playlist" },
]

const Transition = ({ transition }) => {
  const transitionData = transitionList.find(element => {
    return element.transition === transition
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          imageList: allFile(
            filter: { relativeDirectory: { eq: "transition" } }
          ) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 1900, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className={Styles.transition}>
          <div className={Styles.title}>{transitionData["title"]}</div>
          <Img
            alt=""
            style={{
              position: "absolute",
              width: "100%",
              height: "35%",
              zIndex: -1,
            }}
            fluid={
              data.imageList.edges.filter(element =>
                element.node.childImageSharp.fluid.src.includes(
                  transitionData["image"]
                )
              )[0].node.childImageSharp.fluid
            }
          />
        </div>
      )}
    />
  )
}

export default Transition
