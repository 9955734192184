import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Styles from "./newAudioPlayer.module.css"
import ListAudio from "../../data/list-audio"
import AudioPlayerImage from "./audioPlayerImage"

const NewAudioPlayer = () => {
  const myAudio = useRef(null)
  const [title, setTitle] = useState("improNG1")
  const [idSelected, setIdSelected] = useState("blue")
  const [image, setImage] = useState("")

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "mp3" } }, sort: { fields: name }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const AudioDetail = () => {
    return (
      <div className={Styles.listAudio}>
        {ListAudio.map(element => {
          return idSelected === element.id ? (
            <div
              key={element.id}
              id={element.id}
              style={{ color: "#5c8eda", fontWeight: "bold" }}
              className={Styles.detailAudio}
              onClick={event => selectedSong(element)}
            >
              <div className={Styles.number}>{element.key}</div>
              <div className={Styles.list}>{element.title}</div>
            </div>
          ) : (
            <div
              key={element.id}
              style={{ color: "black" }}
              className={Styles.detailAudio}
              onClick={event => selectedSong(element)}
            >
              <div className={Styles.number}>{element.key}</div>
              <div className={Styles.list}>{element.title}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const selectedSong = async value => {
    setIdSelected(value.id)
    setImage(value.image)
    setTitle(value.title)
    await setTitle(value.title)
    const source = data.allFile.edges.filter(file => {
      return file.node.publicURL.includes(value.id)
    })
    UpdateSong(source)
  }

  const UpdateSong = async source => {
    myAudio.current.src = source[0].node.publicURL
    await myAudio.current.play()
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.player}>
        <div className={Styles.audioHeader}>
          <audio
            ref={myAudio}
            controls
            className={Styles.audio}
            preload="none"
            controlsList="nodownload"
          >
            <source type="video/mp4" />
          </audio>

          <div className={Styles.title}>{title}</div>
          <div className={Styles.imgContainer}>
            <AudioPlayerImage image={image} key={title} />
          </div>
          <div className={Styles.description}>
            Interprétation Nathalie Guillermic
          </div>
        </div>

        {AudioDetail()}
      </div>
    </div>
  )
}

export default NewAudioPlayer
