const ListAudio = [
  {
    key: 1,
    id: "01-improNG1",
    title: "Improvisation I par Nathalie Guillermic",
    image: "image1",
  },
  {
    key: 2,
    id: "02-op29N1SB",
    title: "OP.29 N°1 Stephane Blet, Album Raphael ",
    image: "image2",
  },
  {
    key: 3,
    id: "03-oP29N4SB",
    title: "OP.29 N°4 Stephane Blet, Album Raphael ",
    image: "image2",
  },
  {
    key: 4,
    id: "04-oP29N5SB",
    title: "OP.29 N°5 Stephane Blet, Album Raphael ",
    image: "image2",
  },
  {
    key: 5,
    id: "05-leriredujokercristal",
    title: "Le rire du joker (Cristal)",
    image: "image3",
  },
  {
    key: 6,
    id: "06-leriredujokerpiano",
    title: "Le rire du joker (Piano) ",
    image: "image3",
  },
  {
    key: 7,
    id: "07-pingpongcristal",
    title: "Ping Pong (Cristal)",
    image: "image3",
  },
  {
    key: 8,
    id: "08-pingpongpiano",
    title: "Ping Pong (Piano) ",
    image: "image3",
  },
  {
    key: 9,
    id: "09-pleinelunecristal",
    title: "Pleine lune (Cristal)",
    image: "image3",
  },
  {
    key: 10,
    id: "10-pleinelunepiano",
    title: "Pleine lune (Piano)",
    image: "image3",
  },
]

export default ListAudio
