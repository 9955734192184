import React from "react"
import Style from "./card.module.css"
import { FaPhone, FaMailBulk } from "react-icons/fa"

const card = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.card}>
        <div className={Style.title}>Pour réserver</div>
        <div className={Style.cardContent}>
          <div className={Style.flex}>
            <FaPhone color={"#3d3b46"} />
            <a href="tel:06-89-61-72-52">&nbsp;&nbsp;06 89 61 72 52</a>
          </div>
          <div className={Style.flex}>
            <FaMailBulk color={"#3d3b46"} />
            <a href="mailto:lesharmoniques27@orange.fr">
              &nbsp;&nbsp;lesharmoniques27@orange.fr
            </a>
          </div>
        </div>
      </div>
      <div className={Style.card}>
        <div className={Style.title}>Tarifs cours de piano</div>
        <div className={Style.cardContent}>
          {/* 1h : 50 € hors abonnement . Cycle 3h : 186€ [Suppression 1h30]
          Abonnement 10h hebdo : 560 € ( au 1er janvier 2022 ) . Coach concours
          sur demande. 🙂 Voilà !<div>Cours individuel </div> */}
          <span>1h : 50€ hors abonnement</span>
          <span>Cycle 3h : 186€</span>
          <span>
            Abonnement 10h : 560€ <br /> (au 1er janvier 2022)
          </span>
          <span>Coach concours sur demande</span>
        </div>
      </div>
      <div className={Style.card}>
        <div className={Style.title}>Commander le CD</div>
        <div className={Style.cardContent}>
          Disponible à la vente à 25€ l'exemplaire sur commande par email.
          <a href="mailto:lesharmoniques27@orange.fr">
            &nbsp;&nbsp; &nbsp;&nbsp;lesharmoniques27@orange.fr
          </a>
        </div>
      </div>
    </div>
  )
}

export default card
