import React from "react"
import Header from "../components/Layout/header"
import Layout from "../components/Layout/layout"
import Card from "../components/Card/card"
import Transition from "../components/transition"
import CardText from "../components/Card/cardText"
import NewAudioPlayer from "../components/AudioPlayer/newAudioPlayer"
import Video from "../components/video"
import "../style/style.css"

const Index = () => {
  return (
    <Layout>
      <Header header="index" />
      <Card />
      <Transition transition="transition1" />s
      <CardText image="image1" />
      <Transition transition="transition2" />
      <NewAudioPlayer id="playlist" />
      <Video />
    </Layout>
  )
}

export default Index
