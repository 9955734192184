import React from "react"
import Styles from "./video.module.css"

const Video = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>Erik Satie, Gymnopédie N°3</div>

      <div className={Styles.paragraph}>
        Vidéogramme ArGes et enregistrement Sofreson au Musée National J. J.
        Henner à Paris 17
      </div>
      <div className={Styles.video}>
        <iframe
          title="video"
          className={Styles.videoSize}
          src="https://www.youtube.com/embed/VAS-TmNj7a8"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default Video
